import { debounce } from 'throttle-debounce'

const SCROLL_DEBOUNCE_TIME = 100

export const scrollStopEvent = (node: HTMLElement) => {
  const dispatchScrollStop = debounce(SCROLL_DEBOUNCE_TIME, () => {
    node.dispatchEvent(new CustomEvent('scrollStop'))
  })

  node.addEventListener('scroll', dispatchScrollStop, { passive: true })
  window.addEventListener('resize', dispatchScrollStop, { passive: true })

  return {
    destroy: () => {
      node.removeEventListener('scroll', dispatchScrollStop)
      window.removeEventListener('resize', dispatchScrollStop)
    },
  }
}

export default scrollStopEvent
