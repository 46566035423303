<script lang="ts">
import { scrollableDetection, scrollStopEvent } from '$lib/actions'
import type { DATA_VISUALIZATION_TYPE } from '$types/index'
import { onMount } from 'svelte'
import { writable } from 'svelte/store'
import { fade } from 'svelte/transition'

export let items: DATA_VISUALIZATION_TYPE[]

let currentItemIndex = 0

$: currentItem = items[currentItemIndex]

function selectNextItem() {
  currentItemIndex++
  if (currentItemIndex >= items.length) {
    currentItemIndex = 0
  }
}

function selectLastItem() {
  currentItemIndex--
  if (currentItemIndex <= 0) {
    currentItemIndex = 0
  }
}

let itemsListEl: HTMLElement
const listItemsEl: HTMLElement[] = []

const scrollable = writable({
  left: false,
  top: false,
  right: false,
  bottom: false,
})

$: currentItemEl = listItemsEl[currentItemIndex]

$: itemsListEl?.scrollTo({
  left:
    currentItemEl.offsetLeft -
    itemsListEl.clientWidth / 2 +
    currentItemEl.clientWidth / 2,
  top:
    currentItemEl.offsetTop -
    itemsListEl.clientHeight / 2 +
    currentItemEl.clientHeight / 2,
  behavior: 'smooth',
})

function activateSnappedItem() {
  const { scrollLeft, scrollTop, clientWidth, clientHeight } = itemsListEl
  const hCenter = scrollLeft + clientWidth / 2
  const vCenter = scrollTop + clientHeight / 2

  const horizontal = clientWidth > clientHeight

  const currentItemHorizontallyWithin =
    currentItemEl.offsetLeft >= scrollLeft &&
    currentItemEl.offsetLeft + currentItemEl.clientWidth <=
      scrollLeft + clientWidth

  const currentItemVerticallyWithin =
    currentItemEl.offsetTop >= scrollTop &&
    currentItemEl.offsetTop + currentItemEl.clientHeight <=
      scrollTop + clientHeight

  if (!currentItemHorizontallyWithin || !currentItemVerticallyWithin) {
    listItemsEl.forEach((item, index) => {
      if (horizontal) {
        // horizontal
        if (
          item.offsetLeft < hCenter &&
          item.offsetLeft + item.clientWidth > hCenter
        ) {
          currentItemIndex = index
        }
      } else {
        // vertical
        if (
          item.offsetTop < vCenter &&
          item.offsetTop + item.clientHeight > vCenter
        ) {
          currentItemIndex = index
        }
      }
    })
  }
}

let show = false
onMount(async () => {
  currentItemEl = listItemsEl[currentItemIndex]
  if (!('scrollBehavior' in document.documentElement.style)) {
    await import('smoothscroll-polyfill').then(({ polyfill }) => polyfill())
  }
  show = true
})
</script>

<svelte:head>
  {#each items as item}
    {#if item.fields.asset.fields.file.contentType !== 'video/mp4'}
      <link rel="preload" href={item.fields.asset.fields.file.url} as="image" />
    {/if}
  {/each}
</svelte:head>

<section>
  <header>
    <slot name="header" />
  </header>

  <content>
    <current-item-container>
      {#key currentItemIndex}
        {#if show}
          <button transition:fade on:click={selectNextItem}>
            <current-item>
              {#if currentItem.fields.asset.fields.file.contentType === 'video/mp4'}
                <video autoplay muted loop playsinline>
                  <source
                    src={currentItem.fields.asset.fields.file.url}
                    type="video/mp4" />
                </video>
              {:else}
                <img
                  src={currentItem.fields.asset.fields.file.url}
                  alt={currentItem.fields.asset.fields.title} />
              {/if}
            </current-item>
          </button>
        {/if}
      {/key}
      {#if currentItemIndex > 0}
        <button on:click={selectLastItem}>
          <img
            transition:fade
            class="top arrow"
            src="/icons/scroll-arrow.svg"
            alt="scroll-arrow" />
        </button>
      {/if}
      {#if currentItemIndex < items.length - 1}
        <button on:click={selectNextItem}>
          <img
            class="bottom arrow"
            src="/icons/scroll-arrow.svg"
            alt="scroll-arrow" />
        </button>
      {/if}
    </current-item-container>
    <items-list-container
      class:before={$scrollable.left || $scrollable.top}
      class:after={$scrollable.right || $scrollable.bottom}>
      <items-list
        use:scrollStopEvent
        on:scrollStop={activateSnappedItem}
        use:scrollableDetection={scrollable}
        bind:this={itemsListEl}>
        {#each items as item, index}
          <button on:click={() => (currentItemIndex = Number(index))}>
            <list-item
              bind:this={listItemsEl[index]}
              class:selected={currentItemIndex === index}>
              <list-item-title>{item.fields.headline}</list-item-title>
              <list-item-description>
                {item.fields.subheadline}
              </list-item-description>
            </list-item>
          </button>
        {/each}
      </items-list>
    </items-list-container>
  </content>
</section>

<style lang="scss">
button {
  display: contents;
  text-align: left;
}

section {
  margin: 75px 30px 75px 30px;
  height: auto;
  @media only screen and (min-width: 1024px) {
    margin: 100px;
    margin-bottom: 140px;
  }
}
content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media only screen and (min-width: 1280px) {
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
  }
}

current-item-container {
  display: block;
  flex: 1 1 auto;
  width: 100%;
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: 56.25%;
  }

  .arrow {
    position: absolute;
    transition: all 0.1s linear;

    width: 15px;
    height: 42px;
    opacity: 0.4;

    &:hover {
      opacity: 0.8;
    }

    &.top {
      left: 8px;
      transform: rotate(180deg);
      bottom: -18px;
    }
    &.bottom {
      right: 8px;
      bottom: -18px;
    }
  }
}

current-item {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: stretch;

  video,
  img {
    display: inline-block;
    border-radius: 16px;
    overflow: hidden;
    height: auto;
    width: 100%;
    max-height: 100%;
    box-shadow:
      0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  img {
    background-size: cover;
  }
}

items-list-container {
  overflow: auto;
  position: relative;
  flex: 0 0 160px;
  margin: 15px 0 0 0;
}
@media only screen and (min-width: 1280px) {
  items-list-container {
    flex: 0 0 340px;
    margin: 0 0 0 25px;
  }
}

items-list-container {
  --shadow-color: rgba(0, 0, 0, 0.5);

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  &.before::before,
  &.after::after {
    opacity: 0.7;
  }

  @media only screen and (max-width: 1279px) {
    &:before,
    &:after {
      top: 0;
      bottom: 0px;
      width: 40px;
    }
    &:before {
      background-image: radial-gradient(
        farthest-side at 0 50%,
        var(--shadow-color),
        rgba(0, 0, 0, 0)
      );
      left: 0;
    }
    &:after {
      background-image: radial-gradient(
        farthest-side at 100% 50%,
        var(--shadow-color),
        rgba(0, 0, 0, 0)
      );

      right: 0;
    }
  }

  @media only screen and (min-width: 1280px) {
    &:before,
    &:after {
      left: 0;
      right: 0px;
      top: auto;
      bottom: auto;
      width: auto;
      height: 50px;
      opacity: 0;
      transition: opacity 0.2s linear;
    }
    &:before {
      background: radial-gradient(
        farthest-side at 50% 0,
        var(--shadow-color),
        rgba(0, 0, 0, 0)
      );
      top: 0;
    }
    &:after {
      background-image: radial-gradient(
        farthest-side at 50% 100%,
        var(--shadow-color),
        rgba(0, 0, 0, 0)
      );
      bottom: 0;
    }
  }
}

items-list {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  @media only screen and (min-width: 1280px) {
    padding: 0 10px;
    flex-flow: column nowrap;
  }
}
list-item {
  scroll-snap-align: center;

  opacity: 0.5;
  transition: all 0.1s linear;

  &:hover {
    opacity: 0.8;
  }
  &-title {
    display: block;
    font-size: 22px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.16px;
    color: var(--greyLight);
  }
  &-description {
    display: block;
    color: var(--greyLight);
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
    letter-spacing: 0.16px;
  }

  &.selected {
    opacity: 1;
    & list-item-title {
      color: var(--white);
    }
  }
  flex: 0 0 280px;
  padding: 10px 20px;

  @media only screen and (min-width: 640px) {
    flex: 0 0 320px;
    padding: 10px 30px;
  }

  @media only screen and (min-width: 1280px) {
    flex: 0 0 100px;
    padding: 15px 0;
  }
  @media only screen and (min-width: 1400px) {
    padding: 30px 0;
  }
}

@media only screen and (min-width: 1280px) {
  current-item-container {
    button .arrow,
    .arrow {
      width: 19px;
      height: 42px;
      opacity: 0.5;
      background: none;
      border: none;
      &.top {
        top: 5px;
        right: -7px;
        left: auto;
        transform: rotate(270deg);
      }
      &.bottom {
        right: -7px;
        bottom: 5px;
        transform: rotate(90deg);
      }
    }
  }
}
</style>
