import type { Writable } from 'svelte/store'
import { throttle } from 'throttle-debounce'

const THROTTLE_TIME = 100

export const scrollableDetection = (
  node: HTMLElement,
  scrollable: Writable<{
    left: boolean
    right: boolean
    top: boolean
    bottom: boolean
  }>,
) => {
  const detectScrollable = throttle(
    THROTTLE_TIME,
    () => {
      const {
        scrollLeft,
        scrollTop,
        scrollWidth,
        scrollHeight,
        clientWidth,
        clientHeight,
      } = node

      scrollable.set({
        left: scrollLeft > 0,
        right: scrollLeft + clientWidth < scrollWidth,
        top: scrollTop > 0,
        bottom: scrollTop + clientHeight < scrollHeight,
      })
    },
    { debounceMode: false },
  )

  node.addEventListener('scroll', detectScrollable, { passive: true })
  window.addEventListener('resize', detectScrollable, { passive: true })
  detectScrollable()
  return {
    destroy: () => {
      node.removeEventListener('scroll', detectScrollable)
      window.addEventListener('resize', detectScrollable, { passive: true })
    },
  }
}

export default scrollableDetection
